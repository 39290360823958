// vendor
import 'bootstrap';
import 'parsleyjs';
import 'slick-carousel/slick/slick';
import 'smartbanner.js/dist/smartbanner.min';
import 'object-fit-images/dist/ofi';
import toastr from 'toastr';
window.toastr = toastr;

import 'select2/dist/js/select2';
$.fn.select2.defaults.set('theme', 'bootstrap');

// components
import './components/parsley-validators';
import './components/reviews-section-parent';
import './components/contact-us-form';
import './components/main';
import './components/main-banner';
import './components/main-banner-timer';
import './components/menu';
import './components/homepage';
import './components/header';
import './components/go-to-top-btn';
import './components/my-messages';
import './components/website-plans-modal';
import './components/website-sign-up-modal';
import './components/shipping-addon-form';

import './payment/subscription-plans-sample';

import './website/index';
