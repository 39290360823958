import {ENDPOINT} from '../config';
import {
  getError,
  getUserSources,
  toggleButtonLoading
} from '../utils';

$(function () {
  const $subscribeFormContainer = $('#subscribe-form-container');
  const subscribeForm = '#subscribe-form';
  const errorClose = '#subscribe-error-close';
  const errorContainer = '#subscribe-error-container';
  const subscribeSuccess = '#subscribe-success';
  const subscribeError = '#subscribe-error';
  const subscribeFormButton = '#subscribe-form-button';
  const $subscribeFormInput = $subscribeFormContainer.find('#subscribe-email');
  const $subscribeFormButton = $subscribeFormContainer.find(subscribeFormButton);
  const $subscribeForm = $subscribeFormContainer.find(subscribeForm);
  const $subscribeSuccess = $subscribeFormContainer.find(subscribeSuccess);
  const $errorContainer = $subscribeFormContainer.find(errorContainer);
  const $errorText = $subscribeFormContainer.find(subscribeError);

  const emailRegExp = /[\w-]+@([\w-]+\.)+[\w-]+/;

  const emptyEmailErrorMsg = 'Hey, what\'s your e-mail?';
  const invalidEmailErrorMsg = 'Ooops, something is wrong with your e-mail. Please check it.';

  const doOnRequestSuccess = () => {
    $subscribeForm.hide();
    $subscribeSuccess.show();
    toggleButtonLoading($subscribeFormButton, false);
  };

  const doOnRequestError = (error) => {
    const errorText = getError(error);
    $errorText.html(errorText);
    $errorContainer.show();
    toggleButtonLoading($subscribeFormButton, false);
  };

  $(document)
    .on('click', errorClose, (e) => {
      const error = $(e.target).closest(errorContainer);
      error.hide();
    });

  $subscribeForm.submit(function (e) {
    e.preventDefault();

    $errorContainer.hide();
    $errorText.empty();

    let payload = {};

    if (window.isLoggedIn) {
      toggleButtonLoading($subscribeFormButton, true);
      payload.subscribe_to_emails = true;

      $.ajax({
        url: ENDPOINT.USER_PROFILE,
        contentType: 'application/json',
        type: 'PATCH',
        data: JSON.stringify(payload)
      }).then(() => {
        doOnRequestSuccess();
      }, (error) => {
        doOnRequestError(error);
      });

      return;
    }

    let isValid = true;
    const email = $subscribeFormInput.val();

    if (email.length === 0) {
      $errorText.html(emptyEmailErrorMsg);
      isValid = false;
    } else if (!emailRegExp.test(email)) {
      $errorText.html(invalidEmailErrorMsg);
      isValid = false;
    }

    if (!isValid) {
      $errorContainer.show();
      return false;
    }

    toggleButtonLoading($subscribeFormButton, true);

    payload = {
      source: getUserSources('source'),
      medium: getUserSources('medium'),
      campaign: getUserSources('campaign'),
      placement: getUserSources('placement'),
      email: email
    };

    $.ajax({
      url: ENDPOINT.USER_PROFILE,
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify(payload)
    }).then(() => {
      doOnRequestSuccess();
    }, (error) => {
      doOnRequestError(error);
    });
  });
});
