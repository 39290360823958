$(document).ready(function () {
  const $reviewsCarousel = $('[data-selector="parent-reviews-slider"]');

  if ($reviewsCarousel && $reviewsCarousel.length) {
    $reviewsCarousel.not('.slick-initialized').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      swipeToSlide: true,
      adaptiveHeight: true,
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 550,
        settings: {
          slidesToShow: 1
        }
      }]
    });
  }
});
